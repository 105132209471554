<template>
  <TransparentModal>
    <template v-slot:button="{openModal}">
      <button class="btn d-flex flex-column justify-content-center align-items-center p-0 participant__products" @click="openModal" style="text-decoration:underline; color: white; margin: auto;">
        Ver productos participantes
      </button>
    </template>
    <div class="d-flex flex-column justify-content-center align-items-center">
      <div class="image-wrapper">
        <img class="img-fluid product-image" src="https://petalo.mints.cloud/public-assets/productos-participantes" alt="Productos participantes Pétalo" style="width: 600px;">
        <img class="img-fluid shine-image" src="https://petalo.mints.cloud/public-assets/shine" alt="Destello" style="">
      </div>
    </div>
  </TransparentModal>
</template>

<script>

import TransparentModal from "@components/misc/TransparentModal.vue";

export default {
  name: "SpecialEditionModal",
  components: { TransparentModal }
}
</script>

<style>

  .participant__products {
    text-wrap: nowrap;
    line-height: 18px;
  }

  .image-wrapper {
  position: relative;
  display: inline-block;
  width: 700px;
  height: auto;
}

.product-image {
  position: relative;
  z-index: 2;
  width: 100%;
}

.shine-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: 170%;
  width: 170%;
}

@media (min-width: 0px) and (max-width: 766px) {
  .image-wrapper {
    width:100%;
    max-width: 500px;
    height: auto;
  }
  .shine-image {
    height: 150%;
    width: 150%;
  }
}
</style>